/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import LanguageBox from '../../components/LanguageBox/LanguageBox';
import InputOTP from '../../components/InputOTP/InputOTP';
import useTimer from '../../hooks/useTimer';
import useCommonFunctions from '../../hooks/useCommonFunctions';
import useManageOtp from '../../hooks/useManageOtp';
import { ToastContainer } from 'react-toastify';
import AuthController from '../../controllers/AuthController/AuthController';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import useManageModal from '../../hooks/useManageModal';

const logo = require('../../assets/images/fably-logo.png');

function OtpPage() {
  const { t } = useTranslation();
  const { goTo, location } = useCommonFunctions();
  const [otpTime, setOtpTime] = useState(3 * 60);
  const { formattedTime, remainingTime } = useTimer(otpTime);
  const {
    handleVerifyOtp,
    handleResendOtp,
    errorMessage,
    setErrorMessage,
    setEmail,
    loading,
  } = AuthController();
  const { showModal, handleOpenModal, handleCloseModal } = useManageModal();
  const { otp, handleChange, handleBackspace, handlePaste } = useManageOtp(
    (otp) =>
      handleVerifyOtp(otp, () => {
        handleOpenModal();
      })
  );
  const email = location.state?.email;

  useEffect(() => {
    setEmail(email);
  }, [email]);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth');
    if (isAuthenticated) {
      goTo('/plans');
    }
  }, [goTo]);

  return (
    <div className="general-container">
      <div className="fade-screen" />
      <LanguageBox />
      <div className="container sm-pb-150">
        <ToastContainer
          hideProgressBar={true}
          className="toast"
          autoClose={3000}
          pauseOnFocusLoss={false}
        />
        <div className="sub-container-full">
          <img src={logo} alt={t('fablyLogo')} className="fablyLogo" />
          <p className="logo-text">{t('getAccessFullContent')}</p>
          <div className="mid-text">
            <h4>{`${t('step')} 1: ${t('register')} - ${t('confirmation')}`}</h4>
            <p className="nm-bottom-text">{t('confirmEmailText')}</p>
          </div>
          <form className="otp-form" onSubmit={(e) => e.preventDefault()}>
            {otp.map((value, index) => (
              <InputOTP
                key={index}
                index={index}
                value={value}
                onChange={handleChange}
                onBackspace={handleBackspace}
                extraClass={'gm-5'}
                onFocus={() =>
                  document.getElementById(`otp-input-${index}`)?.select()
                }
                id={`otp-input-${index}`}
                onPaste={handlePaste}
              />
            ))}
          </form>
          <p className="nm-text">
            {t('codeExpiration', { otp: formattedTime })}
          </p>
          <span className="inline-span">
            <p className="nm-text">{t('didntReceiveEmail')}</p>
            <p
              onClick={() => {
                if (remainingTime === 0) {
                  handleResendOtp(() => {
                    setOtpTime(60);
                  });
                } else {
                  setErrorMessage(t('waitTimeToResend'));
                }
              }}
              className="nm-text cl-yellow ml-5 cs-pointer"
            >
              {t('resend')}
            </p>
          </span>
          {errorMessage && (
            <p style={{ color: 'red', padding: 10 }}>{errorMessage}</p>
          )}
        </div>
        <ModalComponent
          closeModal={handleCloseModal}
          modalIsOpen={showModal}
          title={t('accountConfirmed')}
          text={t('loginToFinalize')}
          buttonText={t('loginNow')}
          buttonAction={() => goTo('/login')}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default OtpPage;
