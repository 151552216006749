import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import './utils/colors.css';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="background-video-container">
      <video autoPlay loop muted className="background-video">
        <source
          src="https://fably.app/wp-content/uploads/2024/11/video-reel-cuentos-fably-Hero-2.mp4"
          type="video/mp4"
        />
        Tu navegador no soporta videos.
      </video>
      {/* <div className="background-image" /> */}
      <App />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
