import { useState } from 'react';

const useManageOtp = (handleSubmit) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

    if (newOtp.every((num) => num !== '')) {
      if (handleSubmit) handleSubmit(newOtp.join(''));
    }
  };

  const handleBackspace = (index) => {
    if (index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('Text').replace(/\s+/g, '');

    if (/^\d+$/.test(pastedData)) {
      const newOtp = [...otp];
      for (let i = 0; i < otp.length; i++) {
        newOtp[i] = pastedData[i] || ''; // Rellena o deja vacío
      }
      setOtp(newOtp);

      // Enfocar el último campo completado automáticamente
      const lastFilledIndex = Math.min(pastedData.length, otp.length) - 1;
      if (lastFilledIndex >= 0) {
        document.getElementById(`otp-input-${lastFilledIndex}`).focus();
      }
    }
  };

  return { otp, setOtp, handleChange, handleBackspace, handlePaste };
};

export default useManageOtp;
