import Modal from 'react-modal';
import { IoIosClose } from 'react-icons/io';
import './styles.css';
import useManageModal from '../../hooks/useManageModal';
import { SyncLoader } from 'react-spinners';

function ModalComponent({
  children,
  closeModal,
  modalIsOpen,
  afterOpenModal,
  customStyles,
  title,
  text,
  buttonText,
  buttonAction,
  loading,
}) {
  const { modalClass } = useManageModal(modalIsOpen);
  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white',
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      // onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      className={modalClass}
      overlayClassName="dark-overlay"
    >
      <div className="modal-header">
        <button className="close-modal-btn" onClick={closeModal}>
          <IoIosClose color="#ffffff" size={35} />
        </button>
      </div>
      {children ? (
        children
      ) : (
        <div className="modal-body">
          <h3 className="modal-title">{title}</h3>
          <p className="modal-text">{text}</p>
          <button className="modal-btn" onClick={buttonAction}>
            <SyncLoader
              color="#ffffff"
              loading={loading}
              cssOverride={override}
              size={5}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            {!loading && buttonText}
          </button>
        </div>
      )}
    </Modal>
  );
}

export default ModalComponent;
