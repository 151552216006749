import React from 'react';
import './styles.css';

function InputOTP({
  value,
  onChange,
  index,
  onBackspace,
  onFocus,
  onPaste,
  extraClass,
}) {
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && !value) {
      onBackspace(index);
    }
  };

  const handleChange = (e) => {
    const inputVal = e.target.value.replace(/\D/, '');
    if (inputVal.length <= 1) {
      onChange(index, inputVal);
    }
  };

  return (
    <input
      type="text"
      maxLength="1"
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onFocus={onFocus}
      onPaste={onPaste}
      className={`otp-input ${extraClass}`}
      id={`otp-input-${index}`}
    />
  );
}

export default InputOTP;
