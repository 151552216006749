/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import LanguageBox from '../../components/LanguageBox/LanguageBox';
import InputOTP from '../../components/InputOTP/InputOTP';
import useTimer from '../../hooks/useTimer';
import useCommonFunctions from '../../hooks/useCommonFunctions';
import useManageOtp from '../../hooks/useManageOtp';
import { ToastContainer } from 'react-toastify';
import AuthController from '../../controllers/AuthController/AuthController';
import { SyncLoader } from 'react-spinners';

const logo = require('../../assets/images/fably-logo.png');

function ConfirmOtpPasswordPage() {
  const { t } = useTranslation();
  const { goTo, location } = useCommonFunctions();
  const [otpTime, setOtpTime] = useState(3 * 60);
  const { formattedTime, remainingTime } = useTimer(otpTime);
  const {
    errorMessage,
    loading,
    setEmail,
    setErrorMessage,
    handleResendOtp,
    handleVerifyOtpPassword,
  } = AuthController();
  const { otp, handleChange, handleBackspace, handlePaste } = useManageOtp();
  const email = location.state?.email;
  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white',
  };

  useEffect(() => {
    setEmail(email);
  }, [email]);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth');
    if (isAuthenticated) {
      goTo('/plans');
    }
  }, [goTo]);

  return (
    <div className="general-container">
      <div className="fade-screen" />
      <LanguageBox />
      <div className="container sm-pb-150">
        <ToastContainer
          hideProgressBar={true}
          className="toast"
          autoClose={3000}
          pauseOnFocusLoss={false}
        />
        <div className="sub-container">
          <img src={logo} alt={t('fablyLogo')} className="fablyLogo" />
          <div className="mid-text">
            <p className="sub-title">{t('great')}</p>
          </div>
          <form className="otp-form" onSubmit={(e) => e.preventDefault()}>
            {otp.map((value, index) => (
              <InputOTP
                key={index}
                index={index}
                value={value}
                onChange={handleChange}
                onBackspace={handleBackspace}
                extraClass={'gm-5'}
                onFocus={() =>
                  document.getElementById(`otp-input-${index}`)?.select()
                }
                onPaste={handlePaste}
                id={`otp-input-${index}`}
              />
            ))}
          </form>
          <button
            type="button"
            className="loginButton"
            onClick={() =>
              handleVerifyOtpPassword(otp.join().replaceAll(',', ''))
            }
            disabled={loading || !otp.every((item) => item !== '')}
          >
            <SyncLoader
              color="#ffffff"
              loading={loading}
              cssOverride={override}
              size={5}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            {!loading && t('validate')}
          </button>
          <p className="nm-bottom-text bold-text">
            {t('codeExpiration', { otp: formattedTime })}
          </p>
          <span className="inline-span">
            <p className="nm-text">{t('didntReceiveEmail')}</p>
            <p
              onClick={() => {
                if (remainingTime === 0) {
                  handleResendOtp(() => {
                    setOtpTime(60);
                  });
                } else {
                  setErrorMessage(t('waitTimeToResend'));
                }
              }}
              className="nm-text cl-yellow ml-5 cs-pointer"
            >
              {t('resend')}
            </p>
          </span>
          {errorMessage && (
            <p style={{ color: 'red', padding: 10 }}>{errorMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConfirmOtpPasswordPage;
