/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { SyncLoader } from 'react-spinners';
import LanguageBox from '../../components/LanguageBox/LanguageBox';
import AuthController from '../../controllers/AuthController/AuthController';
import PasswordInputWithIcon from '../../components/PasswordInputWithIcon/PasswordInputWithIcon';
import useCommonFunctions from '../../hooks/useCommonFunctions';
import { ToastContainer } from 'react-toastify';

const logo = require('../../assets/images/fably-logo.png');

function ChangePasswordPage() {
  const { t } = useTranslation();
  const { goTo, location } = useCommonFunctions();
  const {
    password,
    repeatPassword,
    errorMessage,
    loading,
    setPassword,
    setRepeatPassword,
    setEmail,
    handleChangePassword,
  } = AuthController();
  const email = location.state?.email;
  const otp = location.state?.otp;

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white',
  };

  useEffect(() => {
    document.title = t('changePassword');
  }, [t]);

  useEffect(() => {
    if (email) setEmail(email);
  }, [email]);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth');

    if (isAuthenticated) {
      goTo('/plans');
    }
  }, [goTo]);

  return (
    <div className="general-container">
      <div className="fade-screen" />
      <LanguageBox />
      <div className="container">
        <ToastContainer
          hideProgressBar={true}
          className="toast"
          autoClose={3000}
          pauseOnFocusLoss={false}
        />
        <div className="sub-container">
          <img src={logo} alt={t('fablyLogo')} className="fablyLogo" />
          <div className="mid-text">
            <p className="sub-title nm-bottom-text">
              {t('enterNewPasswordFinally')}
            </p>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <PasswordInputWithIcon
              className="inputStyle"
              type="password"
              password={password}
              setPassword={setPassword}
              name="password"
              placeholder={t('newPassword')}
              required
            />
            <PasswordInputWithIcon
              className="inputStyle"
              type="password"
              password={repeatPassword}
              setPassword={setRepeatPassword}
              name="repeatPassword"
              placeholder={t('confirmNewPassword')}
              required
            />
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <button
              type="button"
              onClick={() => handleChangePassword(otp)}
              className="loginButton"
              disabled={loading}
            >
              <SyncLoader
                color="#ffffff"
                loading={loading}
                cssOverride={override}
                size={5}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              {!loading && t('update')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordPage;
